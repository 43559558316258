import { createApp } from 'vue';
import { createPinia } from "pinia";
import App from './App.vue';
import router from './router';
import FlagIcon from 'vue-flag-icon';
import VueLazyLoad from 'vue3-lazyload'
import env from "@/helpers/Environment";
import Toaster from "@meforma/vue-toaster";
import { createGtm } from '@gtm-support/vue-gtm';

//primevue components
import Steps from 'primevue/steps';
import Badge from 'primevue/badge';
import Button from 'primevue/button';

import Column from 'primevue/column';
import PrimeVue from 'primevue/config';
import Datatable from 'primevue/datatable'
import Divider from 'primevue/divider';
import InputNumber from 'primevue/inputnumber';
import DialogService from 'primevue/dialogservice';
import ConfirmationService from 'primevue/confirmationservice';
import RadioButton from 'primevue/radiobutton';
import InputSwitch from 'primevue/inputswitch';
import Textarea from 'primevue/textarea';
import Checkbox from 'primevue/checkbox';
import Avatar from 'primevue/avatar'
import Skeleton from "primevue/skeleton"
import Timeline from 'primevue/timeline';
import Tooltip from 'primevue/tooltip';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Breadcrumb from 'primevue/breadcrumb';
import InputText from 'primevue/inputtext';
import Menu from 'primevue/menu';
import Dropdown from 'primevue/dropdown';
import PanelMenu from 'primevue/panelmenu';
import Sidebar from 'primevue/sidebar';
import SelectButton from 'primevue/selectbutton';
import Tag from 'primevue/tag';


//custom components
import CustomStepper2 from '@/components/CustomStepper.vue';
import CustomSpinner2 from '@/components/customSpinner2.vue'
import Logo from '@/views/_layout/_NavLogo.vue'
import CustomDropdown from '@/components/CustomDropdown.vue';
import CustomInfo from '@/components/CustomInfo.vue'
import CustomTag from '@/components/CustomTag.vue'
import CustomCard from '@/components/CustomCard.vue'
import CustomButtonV3 from '@/components/CustomButtonv3.vue'
import CustomDataTable from '@/components/CustomDataTable.vue'
import CustomDropdownFlag from '@/components/CustomDropdownFlag.vue'
import CustomDivider from '@/components/CustomDivider.vue'
import CustomTimeline from '@/components/CustomTimeline.vue'
import CustomBreadcrumb from "@/components/CustomBreadcrumb.vue"

/* 3rd party components */
import ToastService from 'primevue/toastservice';

//import '/node_modules/primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'dropzone/dist/dropzone.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import "@vuepic/vue-datepicker/dist/main.css"


import "@/assets/styles/reset.scss";
//import "@/styles/primevue-sass-theme-main/themes/mytheme/theme.scss";
import 'primevue/resources/themes/aura-light-blue/theme.css'
// import 'primevue/resources/themes/lara-light-blue/theme.css';
// import "@/assets/styles/theme-yellow.css";
import "@/assets/styles/style.scss";

const gtm = createGtm({
  id: env.GTM,
  defer: true,
  compatibility: true,
  enabled: true,
});

export const app = createApp(App);

app.use(router);
app.use(gtm)
app.use(createPinia());
app.use(PrimeVue);
app.use(FlagIcon);
app.use(ConfirmationService);
app.use(DialogService);
app.use(Toaster);  //loynote: to remove and use one toast service only
app.use(ToastService); //loynote: how come no style for toast?
app.use(VueLazyLoad, {
  loading: 'https://stage-media.xxxxxxxxxxxx.com/media/sys/spinner.svg',
  error: 'https://stage-media.xxxxxxxxxxxx.com/media/sys/error.svg',
});

app.component('VueDatePicker', VueDatePicker);
app.component('Avatar', Avatar);
app.component('Badge', Badge);
app.component('Button', Button);
app.component('Breadcrumb', Breadcrumb)  ;
app.component('CheckBox', Checkbox);
app.component('Column', Column);
app.component('Datatable', Datatable);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('InputNumber', InputNumber);
app.component('InputText', InputText);
app.component('InputSwitch', InputSwitch);
app.component('Menu', Menu);
app.component('PanelMenu', PanelMenu);
app.component('RadioButton', RadioButton);
app.component('SelectButton', SelectButton);
app.component('Skeleton', Skeleton);
app.component('Steps', Steps);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Textarea', Textarea);
app.component('Timeline', Timeline);
app.component('Sidebar', Sidebar);
app.component('Tag', Tag);


app.component('CustomSpinner2', CustomSpinner2);
app.component('CustomStepper2', CustomStepper2);
app.component('CustomDropdown', CustomDropdown);
app.component('CustomInfo', CustomInfo);
app.component('Logo', Logo);
app.component('CustomTag', CustomTag);
app.component('CustomCard', CustomCard);

app.component('CustomButtonV3', CustomButtonV3);
app.component('CustomDataTable', CustomDataTable);
app.component('CustomDropdownFlag', CustomDropdownFlag);
app.component('CustomDivider', CustomDivider);
app.component('CustomTimeline', CustomTimeline);
app.component('CustomBreadcrumb', CustomBreadcrumb);

app.directive('tooltip', Tooltip);


app.mount('#app');;
